import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | TerraGear
			</title>
			<meta name={"description"} content={"Behind TerraGear: Tradition, passion, and an unyielding love for football"} />
			<meta property={"og:title"} content={"About | TerraGear"} />
			<meta property={"og:description"} content={"Behind TerraGear: Tradition, passion, and an unyielding love for football"} />
			<meta property={"og:image"} content={"https://playandball.live/img/court_1.jpg"} />
			<link rel={"shortcut icon"} href={"https://playandball.live/img/886647_track_512x512.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://playandball.live/img/886647_track_512x512.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://playandball.live/img/886647_track_512x512.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://playandball.live/img/886647_track_512x512.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://playandball.live/img/886647_track_512x512.png"} />
			<meta name={"msapplication-TileImage"} content={"https://playandball.live/img/886647_track_512x512.png"} />
			<meta name={"msapplication-TileColor"} content={"https://playandball.live/img/886647_track_512x512.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				About Us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Unearth TerraGear's curated collections, a testament to our dedication to football's grand tapestry. From the archives of football jerseys to the essentials for devoted fans, every item reverberates with the game's soul.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image
						src="https://playandball.live/img/form_1.jpg"
						width="320px"
						height="400px"
						object-fit="cover"
						object-position="80%"
						border-radius="15px"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://playandball.live/img/ball_1.jpg"
						width="320px"
						height="400px"
						border-radius="15px"
						object-fit="cover"
					/>
				</Box>
				<Box padding="10px">
					<Image
						src="https://playandball.live/img/form_2.jpg"
						width="320px"
						height="400px"
						border-radius="15px"
						object-fit="cover"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Our Products
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Legacy Jerseys Vault
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Handpicked jerseys that reflect football's rich heritage.
Both vintage classics and contemporary designs available.
Crafted using premium materials to ensure comfort and durability.
Unique options for true football aficionados.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Pitch Perfect Footwear
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								A diverse range of boots catering to various terrains and styles of play.
Featuring both iconic and modern designs from trusted football footwear brands.
Engineered for optimal performance and comfort.
Selections suitable for both professional athletes and weekend warriors.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Athletic & Training Essentials
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Equipments designed for rigorous training sessions and skill-building.
Durability meets functionality, ensuring long-lasting performance.
Tailored for both individual and team dynamics.
Selections that mirror the evolving needs of football training.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Supporters' Corner
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Merchandise that lets you wear your football heart on your sleeve.
Authentic memorabilia, including scarves, mugs, posters, and more.
Revel in the spirit of the game, whether on match day or any day.
Exclusive items that resonate with football enthusiasts.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://playandball.live/img/equip_9.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						border-radius="15px"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://playandball.live/img/equip_1.jpg"
						top={0}
						display="block"
						width="100%"
						border-radius="15px"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					For an exhaustive list of our products and the latest updates on our collections, we encourage you to contact us via email. Our aim is to provide you with a comprehensive overview, ensuring a seamless TerraGear shopping experience.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});